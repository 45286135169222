define("discourse/plugins/chat/discourse/lib/fabricators", ["exports", "discourse/models/bookmark", "discourse/models/category", "discourse/models/group", "discourse/models/user", "discourse/plugins/chat/discourse/models/chat-channel", "discourse/plugins/chat/discourse/models/chat-direct-message", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/models/chat-message-reaction", "discourse/plugins/chat/discourse/models/chat-thread", "discourse/plugins/chat/discourse/models/chat-thread-preview"], function (_exports, _bookmark, _category, _group, _user, _chatChannel, _chatDirectMessage, _chatMessage, _chatMessageReaction, _chatThread, _chatThreadPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  Fabricators are used to create fake data for testing purposes.
  The following fabricators are available in lib folder to allow
  styleguide to use them, and eventually to generate dummy data
  in a placeholder component. It should not be used for any other case.
  */

  let sequence = 0;
  function messageFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const channel = args.channel || channelFabricator();
    const message = _chatMessage.default.create(channel, Object.assign({
      id: args.id || sequence++,
      user: args.user || userFabricator(),
      message: args.message || "@discobot **abc**defghijklmnopqrstuvwxyz [discourse](discourse.org) :rocket: ",
      created_at: args.created_at || moment()
    }, args));
    const excerptLength = 50;
    const text = message.message.toString();
    if (text.length <= excerptLength) {
      message.excerpt = text;
    } else {
      message.excerpt = text.slice(0, excerptLength) + "...";
    }
    return message;
  }
  function channelFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const id = args.id || sequence++;
    const chatable = args.chatable || categoryFabricator();
    const channel = _chatChannel.default.create({
      id,
      chatable_type: (chatable instanceof _category.default ? _chatChannel.CHATABLE_TYPES.categoryChannel : _chatChannel.CHATABLE_TYPES.directMessageChannel) || chatable?.type || args.chatable_type,
      chatable_id: chatable?.id || args.chatable_id,
      title: args.title ? args.title : chatable instanceof _category.default ? "General" : null,
      description: args.description,
      chatable,
      status: args.status || _chatChannel.CHANNEL_STATUSES.open,
      slug: chatable?.slug || chatable instanceof _category.default ? "general" : null,
      meta: Object.assign({
        can_delete_self: true
      }, args.meta || {}),
      archive_failed: args.archive_failed ?? false,
      memberships_count: args.memberships_count ?? 0
    });
    channel.lastMessage = messageFabricator({
      channel
    });
    return channel;
  }
  function categoryFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _category.default.create({
      id: args.id || sequence++,
      color: args.color || "D56353",
      read_restricted: args.read_restricted ?? false,
      name: args.name || "General",
      slug: args.slug || "general"
    });
  }
  function directMessageFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _chatDirectMessage.default.create({
      group: args.group ?? false,
      users: args.users ?? [userFabricator(), userFabricator()]
    });
  }
  function directMessageChannelFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const directMessage = args.chatable || directMessageFabricator({
      id: args.chatable_id || sequence++,
      group: args.group ?? false,
      users: args.users
    });
    return channelFabricator(Object.assign(args, {
      chatable_type: _chatChannel.CHATABLE_TYPES.directMessageChannel,
      chatable_id: directMessage.id,
      chatable: directMessage,
      memberships_count: directMessage.users.length
    }));
  }
  function userFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _user.default.create({
      id: args.id || sequence++,
      username: args.username || "hawk",
      name: args.name,
      avatar_template: "/letter_avatar_proxy/v3/letter/t/41988e/{size}.png",
      suspended_till: args.suspended_till
    });
  }
  function bookmarkFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _bookmark.default.create({
      id: args.id || sequence++
    });
  }
  function threadFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const channel = args.channel || channelFabricator();
    return _chatThread.default.create(channel, {
      id: args.id || sequence++,
      title: args.title,
      original_message: args.original_message || messageFabricator({
        channel
      }),
      preview: args.preview || threadPreviewFabricator({
        channel
      })
    });
  }
  function threadPreviewFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _chatThreadPreview.default.create({
      last_reply_id: args.last_reply_id || sequence++,
      last_reply_created_at: args.last_reply_created_at || Date.now(),
      last_reply_excerpt: args.last_reply_excerpt || "This is a reply",
      participant_count: args.participant_count ?? 0,
      participant_users: args.participant_users ?? []
    });
  }
  function reactionFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _chatMessageReaction.default.create({
      count: args.count ?? 1,
      users: args.users || [userFabricator()],
      emoji: args.emoji || "heart",
      reacted: args.reacted ?? false
    });
  }
  function groupFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return _group.default.create({
      name: args.name || "Engineers"
    });
  }
  function uploadFabricator() {
    return {
      extension: "jpeg",
      filesize: 126177,
      height: 800,
      human_filesize: "123 KB",
      id: 202,
      original_filename: "avatar.PNG.jpg",
      retain_hours: null,
      short_path: "/images/avatar.png",
      short_url: "upload://yoj8pf9DdIeHRRULyw7i57GAYdz.jpeg",
      thumbnail_height: 320,
      thumbnail_width: 690,
      url: "/images/avatar.png",
      width: 1920
    };
  }
  var _default = _exports.default = {
    bookmark: bookmarkFabricator,
    user: userFabricator,
    channel: channelFabricator,
    directMessageChannel: directMessageChannelFabricator,
    message: messageFabricator,
    thread: threadFabricator,
    threadPreview: threadPreviewFabricator,
    reaction: reactionFabricator,
    upload: uploadFabricator,
    category: categoryFabricator,
    directMessage: directMessageFabricator,
    group: groupFabricator
  };
});