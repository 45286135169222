define("discourse/plugins/chat/discourse/lib/chat-messages-manager", ["exports", "@glimmer/tracking", "@ember/application"], function (_exports, _tracking, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatMessagesManager = _exports.default = (_class = class ChatMessagesManager {
    constructor(owner) {
      _initializerDefineProperty(this, "messages", _descriptor, this);
      (0, _application.setOwner)(this, owner);
    }
    get stagedMessages() {
      return this.messages.filterBy("staged");
    }
    get selectedMessages() {
      return this.messages.filterBy("selected");
    }
    clearSelectedMessages() {
      this.selectedMessages.forEach(message => message.selected = false);
    }
    clear() {
      this.messages = [];
    }
    addMessages() {
      let messages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.messages = this.messages.concat(messages).uniqBy("id").sort((a, b) => a.createdAt - b.createdAt);
    }
    findMessage(messageId) {
      return this.messages.find(message => message.id === parseInt(messageId, 10));
    }
    findFirstMessageOfDay(a) {
      return this.messages.find(b => a.getFullYear() === b.createdAt.getFullYear() && a.getMonth() === b.createdAt.getMonth() && a.getDate() === b.createdAt.getDate());
    }
    removeMessage(message) {
      return this.messages.removeObject(message);
    }
    findStagedMessage(stagedMessageId) {
      return this.stagedMessages.find(message => message.id === stagedMessageId);
    }
    findIndexOfMessage(id) {
      return this.messages.findIndex(m => m.id === id);
    }
    findLastMessage() {
      return this.messages.findLast(message => !message.deletedAt);
    }
    findLastUserMessage(user) {
      return this.messages.findLast(message => message.user.id === user.id && !message.deletedAt);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "messages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "stagedMessages", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "stagedMessages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedMessages", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selectedMessages"), _class.prototype)), _class);
});