define("discourse/plugins/chat/discourse/modifiers/chat/on-scroll", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ChatOnScroll = _exports.default = (_class = class ChatOnScroll extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [callback, options] = _ref;
      this.element = element;
      this.callback = callback;
      this.options = options;
      this.element.addEventListener("scroll", this.throttledCallback, {
        passive: true
      });
    }
    throttledCallback(event) {
      this.throttledHandler = (0, _runloop.throttle)(this, this.callback, event, this.options.delay ?? 100, this.options.immediate ?? false);
    }
    cleanup() {
      (0, _runloop.cancel)(this.throttledHandler);
      this.element.removeEventListener("scroll", this.throttledCallback);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "throttledCallback", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "throttledCallback"), _class.prototype)), _class);
});