define("discourse/plugins/chat/discourse/services/chat-drawer-router", ["exports", "@glimmer/tracking", "@ember/service", "discourse/plugins/chat/discourse/components/chat/drawer-routes/channel", "discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-thread", "discourse/plugins/chat/discourse/components/chat/drawer-routes/channel-threads", "discourse/plugins/chat/discourse/components/chat/drawer-routes/channels", "discourse/plugins/chat/discourse/components/chat/drawer-routes/threads"], function (_exports, _tracking, _service, _channel, _channelThread, _channelThreads, _channels, _threads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const ROUTES = {
    "chat.channel": {
      name: _channel.default
    },
    "chat.channel.thread": {
      name: _channelThread.default,
      extractParams: route => {
        return {
          channelId: route.parent.params.channelId,
          threadId: route.params.threadId
        };
      }
    },
    "chat.channel.thread.index": {
      name: _channelThread.default,
      extractParams: route => {
        return {
          channelId: route.parent.params.channelId,
          threadId: route.params.threadId
        };
      }
    },
    "chat.channel.thread.near-message": {
      name: _channelThread.default,
      extractParams: route => {
        return {
          channelId: route.parent.parent.params.channelId,
          threadId: route.parent.params.threadId,
          messageId: route.params.messageId
        };
      }
    },
    "chat.channel.threads": {
      name: _channelThreads.default,
      extractParams: route => {
        return {
          channelId: route.parent.params.channelId
        };
      }
    },
    "chat.threads": {
      name: _threads.default
    },
    chat: {
      name: _channels.default
    },
    "chat.channel.near-message": {
      name: _channel.default,
      extractParams: route => {
        return {
          channelId: route.parent.params.channelId,
          messageId: route.params.messageId
        };
      }
    },
    "chat.channel-legacy": {
      name: _channel.default,
      extractParams: route => {
        return {
          channelId: route.params.channelId,
          messageId: route.queryParams.messageId
        };
      }
    }
  };
  let ChatDrawerRouter = _exports.default = (_class = class ChatDrawerRouter extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "chatHistory", _descriptor2, this);
      _initializerDefineProperty(this, "component", _descriptor3, this);
      _initializerDefineProperty(this, "drawerRoute", _descriptor4, this);
      _initializerDefineProperty(this, "params", _descriptor5, this);
    }
    stateFor(route) {
      this.drawerRoute?.deactivate?.(this.chatHistory.currentRoute);
      this.chatHistory.visit(route);
      this.drawerRoute = ROUTES[route.name];
      this.params = this.drawerRoute?.extractParams?.(route) || route.params;
      this.component = this.drawerRoute?.name || _channels.default;
      this.drawerRoute.activate?.(route);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatHistory", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "component", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "drawerRoute", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "params", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
});