define("discourse/plugins/chat/discourse/templates/connectors/user-card-below-message-button/chat-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.user.can_chat_user}}
    <Chat::UserCardButton @user={{this.user}} />
  {{/if}}
  */
  {
    "id": "6Q54Siix",
    "block": "[[[41,[30,0,[\"user\",\"can_chat_user\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@user\"],[[30,0,[\"user\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"chat/user-card-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/connectors/user-card-below-message-button/chat-button.hbs",
    "isStrictMode": false
  });
});