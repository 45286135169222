define("discourse/plugins/chat/discourse/components/chat-channel-chooser-row", ["exports", "@ember/component", "select-kit/components/select-kit/select-kit-row", "@ember/template-factory"], function (_exports, _component, _selectKitRow, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ChannelTitle @channel={{this.item}} />
  */
  {
    "id": "0Vwx6mQC",
    "block": "[[[8,[39,0],null,[[\"@channel\"],[[30,0,[\"item\"]]]],null]],[],false,[\"channel-title\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-channel-chooser-row.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _selectKitRow.default.extend({
    classNames: ["chat-channel-chooser-row"]
  }));
});