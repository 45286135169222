define("discourse/plugins/chat/discourse/initializers/chat-audio", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MENTION = 29;
  const MESSAGE = 30;
  const CHAT_NOTIFICATION_TYPES = [MENTION, MESSAGE];
  var _default = _exports.default = {
    name: "chat-audio",
    initialize(container) {
      const chatService = container.lookup("service:chat");
      if (!chatService.userCanChat) {
        return;
      }
      const chatAudioManager = container.lookup("service:chat-audio-manager");
      chatAudioManager.setup();
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.registerDesktopNotificationHandler((data, siteSettings, user) => {
          if (user.isInDoNotDisturb()) {
            return;
          }
          if (!user.chat_sound) {
            return;
          }
          if (CHAT_NOTIFICATION_TYPES.includes(data.notification_type)) {
            chatAudioManager.play(user.chat_sound);
          }
        });
      });
    }
  };
});