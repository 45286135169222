define("discourse/plugins/chat/discourse/lib/chat-message-interactor", ["exports", "@glimmer/tracking", "@ember/application", "@ember/object", "@ember/service", "discourse/components/modal/bookmark", "discourse/components/modal/flag", "discourse/lib/ajax-error", "discourse/lib/bookmark", "discourse/lib/utilities", "discourse/models/bookmark", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/chat/discourse/components/chat-message", "discourse/plugins/chat/discourse/lib/chat-message-flag", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/models/chat-message-reaction"], function (_exports, _tracking, _application, _object, _service, _bookmark, _flag, _ajaxError, _bookmark2, _utilities, _bookmark3, _getUrl, _decorators, _discourseI18n, _chatMessage, _chatMessageFlag, _chatMessage2, _chatMessageReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.removeChatComposerSecondaryActions = removeChatComposerSecondaryActions;
  _exports.resetRemovedChatComposerSecondaryActions = resetRemovedChatComposerSecondaryActions;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const removedSecondaryActions = new Set();
  function removeChatComposerSecondaryActions(actionIds) {
    actionIds.forEach(id => removedSecondaryActions.add(id));
  }
  function resetRemovedChatComposerSecondaryActions() {
    removedSecondaryActions.clear();
  }
  let ChatMessageInteractor = _exports.default = (_class = class ChatMessageInteractor {
    constructor(owner, message, context) {
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "dialog", _descriptor2, this);
      _initializerDefineProperty(this, "chat", _descriptor3, this);
      _initializerDefineProperty(this, "chatEmojiReactionStore", _descriptor4, this);
      _initializerDefineProperty(this, "chatEmojiPickerManager", _descriptor5, this);
      _initializerDefineProperty(this, "chatChannelComposer", _descriptor6, this);
      _initializerDefineProperty(this, "chatThreadComposer", _descriptor7, this);
      _initializerDefineProperty(this, "chatChannelPane", _descriptor8, this);
      _initializerDefineProperty(this, "chatThreadPane", _descriptor9, this);
      _initializerDefineProperty(this, "chatApi", _descriptor10, this);
      _initializerDefineProperty(this, "currentUser", _descriptor11, this);
      _initializerDefineProperty(this, "site", _descriptor12, this);
      _initializerDefineProperty(this, "router", _descriptor13, this);
      _initializerDefineProperty(this, "modal", _descriptor14, this);
      _initializerDefineProperty(this, "capabilities", _descriptor15, this);
      _initializerDefineProperty(this, "toasts", _descriptor16, this);
      _initializerDefineProperty(this, "message", _descriptor17, this);
      _initializerDefineProperty(this, "context", _descriptor18, this);
      _defineProperty(this, "cachedFavoritesReactions", null);
      (0, _application.setOwner)(this, owner);
      this.message = message;
      this.context = context;
      this.cachedFavoritesReactions = this.chatEmojiReactionStore.favorites;
    }
    get pane() {
      return this.context === _chatMessage.MESSAGE_CONTEXT_THREAD ? this.chatThreadPane : this.chatChannelPane;
    }
    get emojiReactions() {
      let favorites = this.cachedFavoritesReactions;

      // may be a {} if no defaults defined in some production builds
      if (!favorites || !favorites.slice) {
        return [];
      }
      return favorites.slice(0, 3).map(emoji => {
        return this.message.reactions.find(reaction => reaction.emoji === emoji) || _chatMessageReaction.default.create({
          emoji
        });
      });
    }
    get canEdit() {
      return !this.message.deletedAt && this.currentUser.id === this.message.user.id && this.message.channel?.canModifyMessages?.(this.currentUser);
    }
    get canInteractWithMessage() {
      return !this.message?.deletedAt && this.message?.channel?.canModifyMessages(this.currentUser);
    }
    get canRestoreMessage() {
      return this.message?.deletedAt && (this.currentUser.staff || this.message?.user?.id === this.currentUser.id && this.message?.deletedById === this.currentUser.id) && this.message.channel?.canModifyMessages?.(this.currentUser);
    }
    get canBookmark() {
      return this.message?.channel?.canModifyMessages?.(this.currentUser);
    }
    get canReply() {
      return this.canInteractWithMessage && this.context !== _chatMessage.MESSAGE_CONTEXT_THREAD;
    }
    get canReact() {
      return this.canInteractWithMessage;
    }
    get canFlagMessage() {
      return this.currentUser.id !== this.message?.user?.id && this.message?.userFlagStatus === undefined && this.message.channel?.canFlag && !this.message?.chatWebhookEvent && !this.message?.deletedAt;
    }
    get canRebakeMessage() {
      return this.currentUser.staff && this.message.channel?.canModifyMessages?.(this.currentUser);
    }
    get canDeleteMessage() {
      return this.canDelete && !this.message?.deletedAt && this.message.channel?.canModifyMessages?.(this.currentUser);
    }
    get canDelete() {
      return this.currentUser.id === this.message.user.id ? this.message.channel?.canDeleteSelf : this.message.channel?.canDeleteOthers;
    }
    get composer() {
      return this.context === _chatMessage.MESSAGE_CONTEXT_THREAD ? this.chatThreadComposer : this.chatChannelComposer;
    }
    get secondaryActions() {
      const buttons = [];
      buttons.push({
        id: "copyLink",
        name: _discourseI18n.default.t("chat.copy_link"),
        icon: "link"
      });
      if (this.site.mobileView) {
        buttons.push({
          id: "copyText",
          name: _discourseI18n.default.t("chat.copy_text"),
          icon: "clipboard"
        });
      }
      if (this.canEdit) {
        buttons.push({
          id: "edit",
          name: _discourseI18n.default.t("chat.edit"),
          icon: "pencil-alt"
        });
      }
      if (!this.pane.selectingMessages) {
        buttons.push({
          id: "select",
          name: _discourseI18n.default.t("chat.select"),
          icon: "tasks"
        });
      }
      if (this.canFlagMessage) {
        buttons.push({
          id: "flag",
          name: _discourseI18n.default.t("chat.flag"),
          icon: "flag"
        });
      }
      if (this.canDeleteMessage) {
        buttons.push({
          id: "delete",
          name: _discourseI18n.default.t("chat.delete"),
          icon: "trash-alt"
        });
      }
      if (this.canRestoreMessage) {
        buttons.push({
          id: "restore",
          name: _discourseI18n.default.t("chat.restore"),
          icon: "undo"
        });
      }
      if (this.canRebakeMessage) {
        buttons.push({
          id: "rebake",
          name: _discourseI18n.default.t("chat.rebake_message"),
          icon: "sync-alt"
        });
      }
      return buttons.reject(button => removedSecondaryActions.has(button.id));
    }
    select() {
      let checked = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.message.selected = checked;
      this.pane.onSelectMessage(this.message);
    }
    bulkSelect(checked) {
      const manager = this.message.manager;
      const lastSelectedIndex = manager.findIndexOfMessage(this.pane.lastSelectedMessage.id);
      const newlySelectedIndex = manager.findIndexOfMessage(this.message.id);
      const sortedIndices = [lastSelectedIndex, newlySelectedIndex].sort((a, b) => a - b);
      for (let i = sortedIndices[0]; i <= sortedIndices[1]; i++) {
        manager.messages[i].selected = checked;
      }
    }
    copyText() {
      (0, _utilities.clipboardCopy)(this.message.message);
      this.toasts.success({
        duration: 3000,
        data: {
          message: _discourseI18n.default.t("chat.text_copied")
        }
      });
    }
    copyLink() {
      const {
        protocol,
        host
      } = window.location;
      const channelId = this.message.channel.id;
      const threadId = this.message.thread?.id;
      let url;
      if (this.context === _chatMessage.MESSAGE_CONTEXT_THREAD && threadId) {
        url = (0, _getUrl.default)(`/chat/c/-/${channelId}/t/${threadId}/${this.message.id}`);
      } else {
        url = (0, _getUrl.default)(`/chat/c/-/${channelId}/${this.message.id}`);
      }
      url = url.indexOf("/") === 0 ? protocol + "//" + host + url : url;
      (0, _utilities.clipboardCopy)(url);
      this.toasts.success({
        duration: 1500,
        data: {
          message: _discourseI18n.default.t("chat.link_copied")
        }
      });
    }
    react(emoji, reactAction) {
      if (!this.chat.userCanInteractWithChat) {
        return;
      }
      if (this.pane.reacting) {
        return;
      }
      if (this.capabilities.userHasBeenActive && this.capabilities.canVibrate) {
        navigator.vibrate(5);
      }
      if (this.site.mobileView) {
        this.chat.activeMessage = null;
      }
      if (reactAction === _chatMessageReaction.REACTIONS.add) {
        this.chatEmojiReactionStore.track(`:${emoji}:`);
      }
      this.pane.reacting = true;
      this.message.react(emoji, reactAction, this.currentUser, this.currentUser.id);
      return this.chatApi.publishReaction(this.message.channel.id, this.message.id, emoji, reactAction).catch(errResult => {
        (0, _ajaxError.popupAjaxError)(errResult);
        this.message.react(emoji, _chatMessageReaction.REACTIONS.remove, this.currentUser, this.currentUser.id);
      }).finally(() => {
        this.pane.reacting = false;
      });
    }
    toggleBookmark() {
      this.modal.show(_bookmark.default, {
        model: {
          bookmark: new _bookmark2.BookmarkFormData(this.message.bookmark || _bookmark3.default.createFor(this.currentUser, "Chat::Message", this.message.id)),
          afterSave: savedData => {
            const bookmark = _bookmark3.default.create(savedData);
            this.message.bookmark = bookmark;
            this.appEvents.trigger("bookmarks:changed", savedData, bookmark.attachedTo());
          },
          afterDelete: () => {
            this.message.bookmark = null;
          }
        }
      });
    }
    flag() {
      const model = new _chatMessage2.default(this.message.channel, this.message);
      model.username = this.message.user?.username;
      model.user_id = this.message.user?.id;
      this.modal.show(_flag.default, {
        model: {
          flagTarget: new _chatMessageFlag.default((0, _application.getOwner)(this)),
          flagModel: model,
          setHidden: () => model.set("hidden", true)
        }
      });
    }
    delete() {
      return this.chatApi.trashMessage(this.message.channel.id, this.message.id).catch(_ajaxError.popupAjaxError);
    }
    restore() {
      return this.chatApi.restoreMessage(this.message.channel.id, this.message.id).catch(_ajaxError.popupAjaxError);
    }
    rebake() {
      return this.chatApi.rebakeMessage(this.message.channel.id, this.message.id).catch(_ajaxError.popupAjaxError);
    }
    reply() {
      this.composer.replyTo(this.message);
    }
    edit() {
      this.composer.edit(this.message);
    }
    openEmojiPicker(_, _ref) {
      let {
        target
      } = _ref;
      const pickerState = {
        didSelectEmoji: this.selectReaction,
        trigger: target,
        context: "chat-channel-message"
      };
      this.chatEmojiPickerManager.open(pickerState);
    }
    selectReaction(emoji) {
      if (!this.chat.userCanInteractWithChat) {
        return;
      }
      this.react(emoji, _chatMessageReaction.REACTIONS.add);
    }
    handleSecondaryActions(id) {
      this[id](this.message);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "chatEmojiReactionStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chatEmojiPickerManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "chatChannelComposer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "chatThreadComposer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "chatChannelPane", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "chatThreadPane", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "toasts", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "message", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "context", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "react", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "react"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBookmark", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBookmark"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "flag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "flag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "restore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "restore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rebake", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "rebake"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reply", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEmojiPicker", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEmojiPicker"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectReaction", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "selectReaction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSecondaryActions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSecondaryActions"), _class.prototype)), _class);
});