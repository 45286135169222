define("discourse/plugins/chat/discourse/components/styleguide/chat-composer-message-details", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/chat/discourse/lib/fabricators", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _fabricators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<ChatComposerMessageDetails>">
    <Styleguide::Component>
      <ChatComposerMessageDetails @message={{this.message}} />
    </Styleguide::Component>
  
    <Styleguide::Controls>
      <Styleguide::Controls::Row @name="Mode">
        {{#if this.message.editing}}
          <DButton @action={{this.toggleMode}} @translatedLabel="Reply" />
        {{else}}
          <DButton @action={{this.toggleMode}} @translatedLabel="Editing" />
        {{/if}}
      </Styleguide::Controls::Row>
    </Styleguide::Controls>
  </StyleguideExample>
  */
  {
    "id": "UdTTMEaX",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<ChatComposerMessageDetails>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@message\"],[[30,0,[\"message\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@name\"],[\"Mode\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"message\",\"editing\"]],[[[1,\"        \"],[8,[39,6],null,[[\"@action\",\"@translatedLabel\"],[[30,0,[\"toggleMode\"]],\"Reply\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,6],null,[[\"@action\",\"@translatedLabel\"],[[30,0,[\"toggleMode\"]],\"Editing\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/component\",\"chat-composer-message-details\",\"styleguide/controls\",\"styleguide/controls/row\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-composer-message-details.hbs",
    "isStrictMode": false
  });
  let ChatStyleguideChatComposerMessageDetails = _exports.default = (_class = class ChatStyleguideChatComposerMessageDetails extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "keyValueStore", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
    }
    get message() {
      return _fabricators.default.message({
        user: this.currentUser
      });
    }
    toggleMode() {
      if (this.message.editing) {
        this.message.editing = false;
        this.message.inReplyTo = _fabricators.default.message();
      } else {
        this.message.editing = true;
        this.message.inReplyTo = null;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "keyValueStore", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "message", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "message"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMode"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatComposerMessageDetails);
});