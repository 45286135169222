define("discourse/plugins/chat/discourse/components/chat-thread-tracking-dropdown", ["exports", "select-kit/components/notifications-button", "discourse/plugins/chat/discourse/lib/chat-notification-levels"], function (_exports, _notificationsButton, _chatNotificationLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _notificationsButton.default.extend({
    pluginApiIdentifiers: ["thread-notifications-button"],
    classNames: ["thread-notifications-button"],
    content: _chatNotificationLevels.threadNotificationButtonLevels,
    selectKitOptions: {
      i18nPrefix: "chat.thread.notifications",
      showFullTitle: false,
      btnCustomClasses: "btn-flat"
    }
  });
});