define("discourse/plugins/chat/discourse/components/chat-channel-chooser", ["exports", "select-kit/components/combo-box"], function (_exports, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    pluginApiIdentifiers: ["chat-channel-chooser"],
    classNames: ["chat-channel-chooser"],
    selectKitOptions: {
      headerComponent: "chat-channel-chooser-header"
    },
    modifyComponentForRow() {
      return "chat-channel-chooser-row";
    }
  });
});