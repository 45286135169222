define("discourse/plugins/chat/discourse/components/chat-channel-chooser-header", ["exports", "@ember/component", "select-kit/components/combo-box/combo-box-header", "@ember/template-factory"], function (_exports, _component, _comboBoxHeader, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="select-kit-header-wrapper">
    {{#if this.selectedContent}}
      <ChannelTitle @channel={{this.selectedContent}} />
    {{else}}
      {{i18n "chat.incoming_webhooks.channel_placeholder"}}
    {{/if}}
  
    {{d-icon this.caretIcon class="caret-icon"}}
  </div>
  */
  {
    "id": "Lr5V79a1",
    "block": "[[[10,0],[14,0,\"select-kit-header-wrapper\"],[12],[1,\"\\n\"],[41,[30,0,[\"selectedContent\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@channel\"],[[30,0,[\"selectedContent\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[\"chat.incoming_webhooks.channel_placeholder\"],null]],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[1,[28,[35,3],[[30,0,[\"caretIcon\"]]],[[\"class\"],[\"caret-icon\"]]]],[1,\"\\n\"],[13]],[],false,[\"if\",\"channel-title\",\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat-channel-chooser-header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _comboBoxHeader.default.extend({}));
});