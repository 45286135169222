define("discourse/plugins/chat/discourse/components/chat/message/error", ["exports", "@ember/helper", "discourse/components/d-button", "discourse-common/helpers/i18n", "truth-helpers/helpers/eq", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _helper, _dButton, _i18n, _eq, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Error = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @message.error}}
      <div class="chat-message-error">
        {{#if (eq @message.error "network_error")}}
          <DButton
            class="chat-message-error__retry-btn"
            @action={{fn @onRetry @message}}
            @icon="exclamation-circle"
          >
            <span class="chat-message-error__retry-btn-title">
              {{i18n "chat.retry_staged_message.title"}}
            </span>
            <span class="chat-message-error__retry-btn-action">
              {{i18n "chat.retry_staged_message.action"}}
            </span>
          </DButton>
        {{else}}
          {{@message.error}}
        {{/if}}
      </div>
    {{/if}}
  
  */
  {
    "id": "VcVJSuEj",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"error\"]],[[[1,\"    \"],[10,0],[14,0,\"chat-message-error\"],[12],[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"error\"]],\"network_error\"],null],[[[1,\"        \"],[8,[32,1],[[24,0,\"chat-message-error__retry-btn\"]],[[\"@action\",\"@icon\"],[[28,[32,2],[[30,2],[30,1]],null],\"exclamation-circle\"]],[[\"default\"],[[[[1,\"\\n          \"],[10,1],[14,0,\"chat-message-error__retry-btn-title\"],[12],[1,\"\\n            \"],[1,[28,[32,3],[\"chat.retry_staged_message.title\"],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,1],[14,0,\"chat-message-error__retry-btn-action\"],[12],[1,\"\\n            \"],[1,[28,[32,3],[\"chat.retry_staged_message.action\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,1,[\"error\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"@message\",\"@onRetry\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message/error.js",
    "scope": () => [_eq.default, _dButton.default, _helper.fn, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = Error;
});