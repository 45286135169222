define("discourse/plugins/chat/discourse/modifiers/chat/on-animation-end", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse-common/utils/decorators"], function (_exports, _destroyable, _runloop, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ChatOnAnimationEnd = _exports.default = (_class = class ChatOnAnimationEnd extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [fn] = _ref;
      this.element = element;
      this.fn = fn;
      this.handler = (0, _runloop.schedule)("afterRender", () => {
        this.element.addEventListener("animationend", this.handleAnimationEnd);
      });
    }
    handleAnimationEnd() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.fn?.(this.element);
    }
    cleanup() {
      (0, _runloop.cancel)(this.handler);
      this.element?.removeEventListener("animationend", this.handleAnimationEnd);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleAnimationEnd", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "handleAnimationEnd"), _class.prototype)), _class);
});