define("discourse/plugins/chat/discourse/lib/zoom-check", ["exports", "discourse-common/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isZoomed;
  // return true when the browser viewport is zoomed
  function isZoomed() {
    return !(0, _environment.isTesting)() && visualViewport?.scale !== 1 && document.documentElement.clientWidth / window.innerWidth !== 1;
  }
});